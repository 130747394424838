import React from 'react'
import { Header } from '../components'
import Footer from '../components/organisms/Footer'

export default function Page404() {
  return (
    <div>
      <Header menu="main"  fixed={false}></Header>
      <h3 style={{textAlign: 'center'}}>La página no existe</h3>
      <Footer></Footer>
    </div>
  )
}
